@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  @media screen and (max-width: 1200px) {
    font-size: 12px;
  }
  @media screen and (max-width: 1050px) {
    font-size: 10px;
  }
  @media screen and (max-width: 920px) {
    font-size: 8px;
  }
}

.noSmallScreen {
  @media screen and (max-width: 1300px) {
    display: none !important;
  }
}

:root {
  --scrollbarBgColor: rgba(177, 180, 187, 0.5);
  --scrollbarColor: rgb(175, 179, 185);
}

.scrollbar {
  * {
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbarColor) var(--scrollbarBgColor);
  }

  &::-webkit-scrollbar {
    width: 0.66rem;
    display: block;
  }

  &::-webkit-scrollbar-track {
    background: var(--scrollbarBgColor);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--scrollbarColor);
    border-radius: 14px;
    border: 3px solid var(--scrollbarBgColor);
  }
}

body {
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.text-xxs {
  font-size: 0.625rem /* 10px */;
  line-height: 0.75rem /* 12px */;
}

.tooltip-bar-class {
  opacity: 1 !important;
  padding: 8px 10px !important;
  z-index: 255 !important;
}

/* editor di testo */
strong,
strong > * {
  font-weight: bolder;
}

:not(pre) > code {
  font-family: monospace;
  background-color: #eee;
  padding: 3px;
}

blockquote {
  border-left: 2px solid #ddd;
  margin-left: 0;
  margin-right: 0;
  padding-left: 10px;
  color: #aaa;
  font-style: italic !important;
}

em,
em > * {
  font-style: italic;
}

ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

li {
  display: list-item;
}
